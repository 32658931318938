import { ReactNode } from "react";

import { Box, Toolbar, Typography } from "@mui/material";

import { useAuth } from "../providers/AuthProvider";

import Navbar from "./Navbar/Navbar";

export default function Dashboard({
  children,
  fullWidth,
}: {
  children: ReactNode;
  fullWidth?: boolean;
}) {
  const { currentUser } = useAuth();
  if (!currentUser) return null;

  const commitHash = process.env.NEXT_PUBLIC_COMMIT_HASH;

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          overscrollBehavior: "none",
        }}
      >
        <Toolbar />
        <Box sx={{ m: fullWidth ? 0 : 3 }}>{children}</Box>
        {commitHash && (
          <Box
            sx={{
              mt: 3,
              textAlign: "center",
              width: "100%",
              pb: 1,
            }}
          >
            <Typography variant="caption" color="divider">
              Build {commitHash}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
